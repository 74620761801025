<!-- 居家管控统计 -->
<template>
  <div>
    <div style="margin-bottom:20px">

      <el-date-picker v-model="timetime" size="medium" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" />

      <el-button style="margin-left:20px" type="primary" @click="queryTask()">查询</el-button>
      <el-button type="primary" @click="exportFormInfo" v-loading.fullscreen.lock="fullscreenLoading">导出
      </el-button>
    </div>
    <div style="margin-top:20px">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="orgAlias" label="社区卫生服务中心" width="150">
        </el-table-column>
        <el-table-column label="居家隔离">
          <el-table-column prop="isolationPipeCount" label="在管人数" width="120">
          </el-table-column>

          <el-table-column prop="isolationAddCount" label="新增人数" width="120">
          </el-table-column>

          <el-table-column prop="isolationOutCount" label="转出人数" width="120">
          </el-table-column>



        </el-table-column>
        <el-table-column label="居家健康监测">
          <el-table-column prop="healthyPipeCount" label="在管人数" width="120">
          </el-table-column>
          <el-table-column prop="healthyAddCount" label="新增人数" width="120">
          </el-table-column>
          <el-table-column prop="healthyOutCount" label="转出人数" width="120">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="covidSamplingCount" label="核酸采样次数"></el-table-column>
        <el-table-column prop="covidReportCount" label="核酸报告次数"></el-table-column>
        <el-table-column prop="antibodyTestCount" label="抗体检测次数"></el-table-column>
        <el-table-column prop="antibodyReportCount" label="抗体报告次数"></el-table-column>
        <el-table-column prop="tempCount" label="体温检测次数"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  findHomeRegStatistics,
  exportHomeRegStatisticsList
} from "@/api/Taskmanagement/upcomingTasks.js";
import { importFile, messageBox } from "../../utils/utils";

export default {
  data() {
    return {
      fullscreenLoading: false,
      tableData: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      timetime: []

    };
  },
  created() {
    this.queryTask();
  },
  components: {},

  computed: {},

  mounted: {},

  methods: {
    // 查询待处理任务
    async queryTask() {

      let params = {

      };
      if (this.timetime && this.timetime.length == 2) {
        params.startDate = this.timetime[0] + " 00:00:00";
        params.endDate = this.timetime[1] + " 23:59:59";
      }
      const { data } = await findHomeRegStatistics({ params })
      if (data.code == '200') {
        this.tableData = data.data;
      }
    },
    // 导出
    exportFormInfo() {
      let params = {
        ...this.cx,
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
      };
      if (this.timetime && this.timetime.length == 2) {
        params.startDate = this.timetime[0] + " 00:00:00";
        params.endDate = this.timetime[1] + " 23:59:59";
      }
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportHomeRegStatisticsList(params).then((res) => {
            console.log(11111111111, res);
            this.fullscreenLoading = false;
            importFile(res.data, "居家管控信息");
          });
        },
        "warning",
        "是否确认导出 居家管控信息 数据？"
      );
    },
  }
}

</script>
<style lang='scss' scoped>
</style>